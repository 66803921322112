import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { graphql } from 'gatsby';
import Hero from 'components/hero';
import Contact from 'components/contact';
import { Content, Main, Container, ContentBox } from '../templates/service.css';

const PolitykaPrywatnosci = ({ data }) => {
  const { heroImage, title, content } = data.politykaPrywatnosciJson;
  const {
    siteMetadata: {
      companyInfo: { email, phone },
    },
  } = data.site;
  return (
    <Layout>
      <Hero title={title} image={heroImage} />
      <div style={{ height: '2rem' }} />
      <Main>
        <Container>
          <ContentBox>
            <Content
              dangerouslySetInnerHTML={{
                __html: content.childMarkdownRemark.html,
              }}
            />
          </ContentBox>
        </Container>
      </Main>
    </Layout>
  );
};

PolitykaPrywatnosci.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PolitykaPrywatnosci;

export const query = graphql`
  query PrivacyPageQuery {
    site {
      siteMetadata {
        companyInfo {
          phone
          email
        }
      }
    }
    politykaPrywatnosciJson {
      title
      heroImage {
        childImageSharp {
          fluid(maxHeight: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
